import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Post from '../templates/Post';
import Meta from '../components/Meta';
import Layout from '../components/Layout';
import Interstitial from '../components/Interstitial';
import Disclaimer from '../components/Disclaimer';

const WhatsAppHellada = ({ data, location }) => (
  <Layout location={location}>
    <Meta
      title="Σώσε το μέλλον μας! Ψήφισε στις ευρωεκλογές! Δες πώς μπορείς!"
      description="Κάνε κλικ στο σύνδεσμο και στείλε μήνυμα στους φίλους σου στο WhatsApp - δώσε τους την ευκαιρία να ακουστούν!"
      shareImage="hellada"
      location={location}
      site={get(data, 'site.meta')}
      whatsAppMessage={`Γειά σου! Είσαι Ελληνίδα η Έλληνας και μένεις στην Αγγλία; Έχεις το δικαίωμα να ψηφίσεις στις επόμενες ευρωεκλογές!

ΣΗΜΑΝΤΙΚΟ !!!
💥 Στείλε αυτό το μήνυμα στους Έλληνες φίλους σου στο Ηνωμένο Βασίλειο.  💥
Κάτε κλικ εδώ: https://saveourfuture.uk/hellada
Εγγράψου για να ψηφίσεις:

*Εγγράψου στον εκλογικό κατάλογο * - 2 λεπτά
ακολούθησε τα βήματα εδώ:
https://saveourfuture.uk/simple

* Η προθεσμία είναι έως τις 7 Μαΐου.*
(μπορείς να επιλέξεις να ψηφίσεις ταχυδρομικώς - ""ταχυδρομική ψηφοφορία"")

* ΕΠΕΙΓΟΝ !!!
💥 Στείλε αυτό το μήνυμα στους Έλληνες φίλους σου στο Ηνωμένο Βασίλειο. 💥
Κάντε κλικ εδώ: https://saveourfuture.uk/hellada

🗣 * ΑΥΤΗ ΤΗ ΦΟΡΑ Η ΦΩΝΗ ΜΑΣ ΜΕΤΡΑ !!! * 🗣`}
    />
    <Interstitial>
      <p>Επιλέξτε πέντε κορυφαίους φίλους ή ομάδες για να στείλετε το μήνυμα Whatsapp</p>
      <p>Δημιουργία μηνύματος τώρα</p>
      <p>Μπορείτε να προσωποποιήσετε το μήνυμα στο επόμενο βήμα ...</p>
    </Interstitial>
    <Disclaimer />
  </Layout>
);

WhatsAppHellada.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default WhatsAppHellada;

export const query = graphql`
  query HelladaQuery {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
        twitter
        adsense
      }
    }
  }
`;
