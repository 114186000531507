import React, { Component } from 'react';
import PropTypes from 'prop-types';

const DefaultInterstitial = () => (
  <>
    <p>Choose five top friends or groups to send your Whatsapp message</p>
    <p>Generating message now</p>
    <p>You can personalise the message in the next step&hellip;</p>
  </>
)

const Interstitial = ({children }) => (
  <div className="interstitial content wrap">
    <div className="boldGreen textUpper">
      {children}
    </div>
    <p className="countDown textUpper">3, 2, 1&hellip;</p>
  </div>
)

Interstitial.defaultProps = {
  children: React.createElement(DefaultInterstitial),
};

export default Interstitial;
