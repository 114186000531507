import React, { Component } from 'react';
import PropTypes from 'prop-types';

const DefaultDisclaimer = () => (
  <p>
    Disclaimer! All translations have been crowdsourced by native language volunteers
    but if you spot a translation error, please let us know at&nbsp;
    <a href="mailto:saveourfuture@forwarddemocracy.com">saveourfuture@forwarddemocracy.com</a>
  </p>
)

const Disclaimer = ({children }) => (
  <div className="disclaimer content wrap">
    {children}
  </div>
)

Disclaimer.defaultProps = {
  children: React.createElement(DefaultDisclaimer),
};

export default Disclaimer;
